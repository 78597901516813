<script lang="ts" setup>
import { ref, watch, getCurrentInstance } from "vue";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import useDateTimeFormatter from "@/composables/formatDateTime.js";
import { severityCode } from "@/services/api/httpClient";
import { getReportDetails, addReport, updateReport, deleteReport } from "@/services/api/powerbi.api";
import router from "@/router/index";
import DateInput from "@/components/general/DateInput.vue";

const { formatDateIsoD, parseDateIso } = useDateTimeFormatter();

const props = defineProps({
  id: {
    type: String,
    default: "",
  },
  partyReference: {
    type: String,
    default: undefined,
  },
});

const reportData = ref({
  id: undefined,
  name: "",
  order: 1,
  reportDate: undefined,
  createdBy: undefined,
  createdDate: undefined,
  modifiedBy: undefined,
  modifiedDate: undefined,
  reportId: "",
  workspaceId: "",
});
const showTest = ref(false);
const testWorkspaceId = ref("");
const testReportId = ref("");
const componentKey = ref(1);
const isSaving = ref(false);

const isNew = ref(true);

watch(
  () => props.id,
  () => {
    isNew.value = !props.id;
    if (!isNew.value) {
      fetchData();
    }
  },
  { immediate: true }
);

//hack voor vue 2.7
const proxy = getCurrentInstance().proxy;

function parseDates() {
  reportData.value.createdDate = parseDateIso(reportData.value.createdDate);
  reportData.value.modifiedDate = parseDateIso(reportData.value.modifiedDate);
}

function formatDates() {
  reportData.value.createdDate = formatDateIsoD(reportData.value.createdDate);
  reportData.value.modifiedDate = formatDateIsoD(reportData.value.modifiedDate);
}

function testEmbedded() {
  showTest.value = true;
  testWorkspaceId.value = reportData.value.workspaceId;
  testReportId.value = reportData.value.reportId;
  componentKey.value++;
}

async function fetchData() {
  const response = await getReportDetails(props.id);
  //console.log("powerbireportdetails fetchData", response);
  if (response.data != null && response.error == null) {
    reportData.value = response.data;
    formatDates();
    testEmbedded();
  } else {
    proxy.$toaster.error("Fout bij ophalen rapportage gegevens");
  }
}

const obs = ref(null);

async function onSave() {
  isSaving.value = true;
  const valid = await obs.value.validate();
  if (valid) {
    parseDates();

    var ok = false;
    if (isNew.value) {
      reportData.value.createdDate = undefined;
      ok = await doAddReport();
    } else {
      ok = await doUpdateReport();
    }

    if (ok) router.push({ name: "Party", params: { partyReference: props.partyReference } });
    isSaving.value = false;
  }
}

function onCancel() {
  router.push({ name: "Party", params: { partyReference: props.partyReference } });
}

async function onDelete() {
  if (confirm("Weet u zeker dat u deze rapportage wilt verwijderen?")) {
    if (await doDeleteReport()) {
      router.push({ name: "Party", params: { partyReference: props.partyReference } });
    }
  }
}

async function doAddReport() {
  reportData.value.partyReference = props.partyReference;
  const response = await addReport(reportData.value);
  if (response.severity <= severityCode.warning) {
    proxy.$toaster.success("Rapport is successvol toegevoegd.");
    return true;
  } else {
    proxy.$toaster.error("Fout bij opslaan rapportage gegevens");
    return false;
  }
}

async function doUpdateReport() {
  const response = await updateReport(props.id, reportData.value);
  //console.log("powerbireportdetails doUpdateReport", response);
  if (response.severity <= severityCode.warning) {
    proxy.$toaster.success("Rapportgegevens successvol gewijzigd.");
    return true;
  } else {
    proxy.$toaster.error("Fout bij opslaan rapportage gegevens");
    return false;
  }
}

async function doDeleteReport() {
  isSaving.value = true;
  const response = await deleteReport(props.id);
  isSaving.value = false;
  if (response.severity <= severityCode.warning) {
    proxy.$toaster.success("Rapportgegevens successvol gewijzigd.");
    return true;
  } else {
    proxy.$toaster.error("Fout bij verwijderen rapportage gegevens");
    return false;
  }
}

function checkDateValid() {
  return { on: ["input"] };
}
</script>

<template>
  <v-container fluid class="app-user-detail">
    <ValidationObserver ref="obs">
      <v-form>
        <v-row>
          <v-col>
            <h1>Rapportage bewerken</h1>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6" class="form-group">
            <ValidationProvider name="Naam" rules="required" v-slot="{ errors }">
              <v-text-field hide-details="auto" label="Naam" placeholder="Naam" persistent-placeholder outlined v-model="reportData.name" :error-messages="errors"></v-text-field>
            </ValidationProvider>
          </v-col>
          <v-col cols="3" class="form-group">
            <ValidationProvider name="Datum" rules="" v-slot="{ errors }" :mode="checkDateValid">
              <date-input v-model="reportData.reportDate" label="Rapport datum" persistent-placeholder :errors="errors" outlined></date-input>
            </ValidationProvider>
          </v-col>
          <v-col cols="3" class="form-group">
            <ValidationProvider name="Volgorde" rules="between:1,99" v-slot="{ errors }">
              <v-text-field
                hide-details="auto"
                label="Volgorde"
                placeholder="Volgorde"
                persistent-placeholder
                outlined
                v-model="reportData.order"
                :error-messages="errors"
                type="number"
              ></v-text-field>
            </ValidationProvider>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6" class="form-group">
            <ValidationProvider name="Workspace Id" rules="required|guid" v-slot="{ errors }">
              <v-text-field
                hide-details="auto"
                label="Workspace Id"
                placeholder="Workspace Id"
                persistent-placeholder
                outlined
                v-model="reportData.workspaceId"
                :error-messages="errors"
              ></v-text-field>
            </ValidationProvider>
          </v-col>
          <v-col cols="6" class="form-group">
            <ValidationProvider name="Report Id" rules="required|guid" v-slot="{ errors }">
              <v-text-field hide-details="auto" label="Report Id" placeholder="Report Id" persistent-placeholder outlined v-model="reportData.reportId" :error-messages="errors"></v-text-field>
            </ValidationProvider>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="auto" class="form-group">
            <v-btn dark type="button" class="secondary" title="Testen" @click="testEmbedded"> Testen </v-btn>
          </v-col>
          <v-col cols="auto" class="form-group">
            <v-btn dark class="primary" title="Opslaan" @click.prevent="onSave()" :disabled="isSaving">
              <v-icon dark left>mdi-checkbox-marked-circle</v-icon>
              Opslaan
            </v-btn>
          </v-col>
          <v-col cols="auto" class="form-group">
            <v-btn dark class="primary" title="Annuleren" @click.prevent="onCancel()" :disabled="isSaving">
              <v-icon dark left>mdi-cancel</v-icon>
              Annuleren
            </v-btn>
          </v-col>
          <v-spacer />
          <v-col cols="auto" class="form-group" v-if="!isNew">
            <v-btn dark class="secondary" title="Verwijderen" @click.prevent="onDelete()" :disabled="isSaving">
              <v-icon dark left>mdi-delete</v-icon>
              Verwijderen
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <powerbi-viewer v-if="showTest" :workspaceId="testWorkspaceId" :reportId="testReportId" :componentKey="componentKey" class="dropzone" />
          </v-col>
        </v-row>
      </v-form>
    </ValidationObserver>
  </v-container>
</template>
